/** N-body animation adapted from https://bl.ocks.org/mbostock/3031319 */

import React from "react"
import * as d3 from "d3-v3" // v3

const rotate = [-71.03, 42.37]
const velocity = [0.018, 0.006]

const circleRadius = 150
const height = 500
const width = 960

const stopColor1 = "#40C4A0"
const stopColor2 = "#032643"

class ResultsAnimation extends React.Component {
  componentDidMount() {
    this.drawGlobe()
  }

  drawGlobe() {
    const projection = d3.geo.orthographic().scale(circleRadius).clipAngle(90)

    const path = d3.geo.path().projection(projection)
    const graticule = d3.geo.graticule()

    const svg = d3
      .select("#results-animation")
      .append("svg")
      .attr("width", width)
      .attr("height", height)

    const gradient = svg
      .append("defs")
      .append("radialGradient")
      .attr("id", "gradient")
      .attr("cx", "75%")
      .attr("cy", "25%")

    gradient.append("stop").attr("offset", "5%").attr("stop-color", stopColor1)

    gradient
      .append("stop")
      .attr("offset", "100%")
      .attr("stop-color", stopColor2)

    const fill = svg
      .append("circle")
      .attr("cx", width / 2)
      .attr("cy", height / 2)
      .attr("r", circleRadius)
      .style("fill", "url(#gradient)")

    const feature = svg.append("path").datum(graticule)

    d3.timer(function (elapsed) {
      projection.rotate([
        rotate[0] + elapsed * velocity[0],
        rotate[1] + elapsed * velocity[1],
      ])
      fill.attr(
        "transform",
        "rotate(" +
          (rotate[0] + elapsed * velocity[0]) +
          " " +
          width / 2 +
          "," +
          height / 2 +
          ")"
      )
      feature.attr("d", path)
    })
  }

  componentWillUnmount() {
    // trash timer?
  }

  render() {
    return (
      <div className="results-animation">
        <div className="outside-wrapper">
          <div className="animation-container">
            <div id="results-animation" className="animation"></div>
          </div>
        </div>
      </div>
    )
  }
}

export default ResultsAnimation
