/**
 * This component is used on mobile to showcase multiple content blurbs and
 * images or animations. A button is rendered for each piece of content provided.
 * User can click back and forth between them.
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import { IsVisible } from "../components"
const Leadership = loadable(() => import("../components/LeadershipAnimation"))
const ResultsAnimation = loadable(() =>
  import("../components/ResultsAnimation")
)

const MobileContentCarousel = (props) => {
  const [selectedIndex, setSelectedIndex] = useState(0)
  const { content, darkMode, images } = props

  const renderButtons = (numberOfButtons) => {
    const buttons = []
    for (let i = 0; i < numberOfButtons; i++) {
      buttons.push(
        <div
          key={i}
          className={`content-button ${
            selectedIndex === i ? "button-selected" : ""
          }`}
          onClick={() => setSelectedIndex(i)}
        ></div>
      )
    }
    return buttons
  }

  const getGatsbyImage = (image_name) => {
    const img = images.find((image) => {
      const image_split_array =
        image.childImageSharp.gatsbyImageData.images.fallback.src.split("/")
      const file_name = image_split_array[image_split_array.length - 1]
      return file_name === image_name
    })
    console.warn(img)
    return getImage(img)
  }

  const selectedContent = content[selectedIndex]
  const img = selectedContent?.primaryImage

  let imageAnimation
  switch (selectedContent.heading) {
    case "Leadership":
      imageAnimation = (
        <IsVisible margin="350px" placeholderHeight="300px">
          <Leadership />
        </IsVisible>
      )
      break
    case "Results":
      imageAnimation = (
        <IsVisible margin="350px" placeholderHeight="300px">
          <ResultsAnimation />
        </IsVisible>
      )
      break
    default:
      imageAnimation = (
        <GatsbyImage
          alt={selectedContent ? selectedContent.heading : ""}
          className="image"
          image={getGatsbyImage(img)}
          key={img}
        />
      )
  }

  return (
    <section className="mobile-content-carousel">
      <div className="container">
        <div className="flex-column center">
          <div className="image-container">{imageAnimation}</div>
          <h3 className={darkMode ? "green-text" : "blue-text"}>
            {selectedContent.heading}
          </h3>
          <p className={`small ${darkMode ? "white-text" : "black-text"}`}>
            {selectedContent.description}
          </p>
          <div className="flex justify-center">
            {renderButtons(content.length)}
          </div>
        </div>
      </div>
    </section>
  )
}

MobileContentCarousel.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  darkMode: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      childImageSharp: PropTypes.object,
    })
  ),
}

export default MobileContentCarousel
