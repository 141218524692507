import React, { useState } from "react"
import { Link, navigate } from "gatsby"
import left from "../images/svgs/left.svg"
import right from "../images/svgs/right.svg"
import { useContentfulOurWork } from "../components/hooks/useContentfulOurWork"
import _ from "lodash"
import { GatsbyImage } from "gatsby-plugin-image"

const OurWork = ({ pageProject }) => {
  const { projects } = useContentfulOurWork()
  const projectIndex = _.findIndex(projects, (project) => {
    return project.id === pageProject.id
  })
  const [selectedIndex, setSelectedIndex] = useState(projectIndex || 0)
  const selectedProject = projects[selectedIndex]
  const { client, subtitle, primaryImage, slug } = selectedProject || {}

  if (_.isEmpty(selectedProject)) {
    return null
  }

  // if a page project is passed in, then the go to previous
  // navigates to the previous project instead of sliding to it
  function handleGoToPreviousProject() {
    const previous =
      selectedIndex > 0
        ? (selectedIndex - 1) % projects.length
        : projects.length - 1
    if (pageProject) {
      const previousProject = projects[previous]
      navigate(`/our-work/${previousProject?.slug}`)
    } else {
      setSelectedIndex(previous)
    }
  }

  // if a page project is passed in, then the go to next
  // navigates to the next project instead of sliding to it
  function handleGoToNextProject() {
    const next = (selectedIndex + 1) % projects.length
    if (pageProject) {
      const nextProject = projects[next]
      navigate(`/our-work/${nextProject?.slug}`)
    } else {
      setSelectedIndex(next)
    }
  }

  return (
    <div className="hero-carousel">
      <GatsbyImage className="image" image={primaryImage.gatsbyImageData} />
      <div className="layout">
        <img
          alt="left button"
          className="arrow-button left"
          onClick={handleGoToPreviousProject}
          src={left}
        />
        <div className="content-container">
          <Link to={`/our-work/${slug}`}>
            <h2>{client}</h2>
            <h1>{subtitle}</h1>
          </Link>
        </div>
        <img
          alt="right button"
          className="arrow-button right"
          onClick={handleGoToNextProject}
          src={right}
        />
      </div>
    </div>
  )
}

export default OurWork
