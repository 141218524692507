import React from "react"
import PropTypes from "prop-types"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
import { BLOCKS, INLINES } from "@contentful/rich-text-types"
import { GatsbyImage } from "gatsby-plugin-image"
import ReactMarkdown from "react-markdown"
import _ from "lodash"

import useContentfulImage from "./hooks/useContentfulImage"
import useContentfulCodeBlock from "./hooks/useContentfulCodeBlock"

const website_url = "https://futurefoundry.co"

const hyperlink = (node) => {
  const uri = _.get(node, "data.uri")
  const value = _.get(node, "content[0].value")

  return (
    <a
      href={uri}
      target={`${uri.startsWith(website_url) ? "_self" : "_blank"}`}
      rel={`${uri.startsWith(website_url) ? "" : "noopener noreferrer"}`}
    >
      {value}
    </a>
  )
}

const embeddedAsset = (node) => {
  const title = _.get(node, "data.target.fields.title['en-US']")
  const image = useContentfulImage(_.get(node, "data.target.sys.id"))
  return (
    <GatsbyImage
      className="body-image"
      title={title}
      image={image}
      imgStyle={{ objectFit: "contain" }}
    />
  )
}

const embeddedEntry = (node) => {
  // const fields = _.get(node, "data.target.fields")
  const source = useContentfulCodeBlock(
    _.get(node, "data.target.sys.contentful_id")
  )
  return <ReactMarkdown>{source}</ReactMarkdown>
}

const RichText = ({ document, options }) => {
  const defaultOptions = {
    renderNode: {
      [BLOCKS.EMBEDDED_ASSET]: embeddedAsset,
      [INLINES.HYPERLINK]: hyperlink,
      [BLOCKS.EMBEDDED_ENTRY]: embeddedEntry,
    },
    renderText: (text) =>
      text
        .replace(/ {2}/g, "\u00a0\u00a0")
        .split("\n")
        .flatMap((text, i) => [i > 0 && <br />, text]),
  }
  return documentToReactComponents(document, { ...defaultOptions, options })
}

RichText.propTypes = {
  document: PropTypes.object,
  options: PropTypes.object,
}

export default RichText
