import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"

const useContentfulCodeBlock = (contentful_id) => {
  const { allContentfulCodeBlock } = useStaticQuery(
    graphql`
      query CONTENTFUL_CODE_BLOCK_QUERY {
        allContentfulCodeBlock {
          nodes {
            name
            contentful_id
            code {
              id
              code
            }
          }
        }
      }
    `
  )
  return _.get(
    _.find(allContentfulCodeBlock.nodes, { contentful_id }),
    "code.code"
  )
}

export default useContentfulCodeBlock
