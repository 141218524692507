import React from "react"
import * as d3 from "d3-v4" // v3
import forceBounce from "d3-force-bounce"
import forceConstant from "d3-force-constant"

class NewtonsCradleAnimation extends React.Component {
  state = {
    width: window.innerWidth,
  }

  constructor(props) {
    super(props)

    this.canvasHeight
    this.canvasWidth
    this.WIRE_LENGTH
    this.BALL_RADIUS = 15
    this.GRAVITY = 0.6
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions)
    this.determineScreenSizeAndDraw(true)
  }

  componentDidUpdate(prevProps, prevState) {
    const { width } = this.state
    if (prevState.width !== width) {
      this.determineScreenSizeAndDraw(false, prevState.width)
    }
  }

  // TODO: soon to be depricated, refactor asap
  componentWillUnmount() {
    window.removeEventListener("resize", this.updateDimensions)
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  determineScreenSizeAndDraw = (initial, prevWidth) => {
    const { width } = this.state
    const shouldDrawDesktop = width > 767 && (prevWidth < 768 || initial)
    const shouldDrawMobile = width < 768

    if (!shouldDrawDesktop && !shouldDrawMobile) {
      return
    }

    d3.selectAll("svg#newtonsCradle > *").remove()

    if (shouldDrawDesktop) {
      this.canvasWidth = 300
      this.canvasHeight = 590
      this.WIRE_LENGTH = 176
    } else if (shouldDrawMobile) {
      this.canvasWidth = width
      this.canvasHeight = 400
      this.WIRE_LENGTH = 125
    }

    this.drawCradle()
  }

  drawCradle = () => {
    const svgCanvas = d3
        .select("svg#newtonsCradle")
        .attr("width", this.canvasWidth)
        .attr("height", this.canvasHeight),
      wiresG = svgCanvas.append("g"),
      ballsG = svgCanvas.append("g")

    svgCanvas
      .append("rect")
      .attr("x", this.canvasWidth / 2 - 4 * this.BALL_RADIUS - 40)
      .attr("y", (d) => (this.canvasWidth === 300 ? 205 : 130))
      .attr("width", this.BALL_RADIUS * 8 + 80)
      .attr("height", 14)
      .attr("rx", 5)
      .attr("ry", 5)
      .attr("fill", "white")

    const balls = [
        {
          id: "0",
          init: {
            x: this.canvasWidth / 2 - 6 * this.BALL_RADIUS,
            y: (this.canvasHeight * 2) / 3,
          },
        },
        {
          id: "1",
          init: {
            x: this.canvasWidth / 2 - 4 * this.BALL_RADIUS,
            y: (this.canvasHeight * 2) / 3,
          },
        },
        {
          id: "2",
          init: {
            x: this.canvasWidth / 2 - 2 * this.BALL_RADIUS,
            y: (this.canvasHeight * 2) / 3,
          },
        },
        {
          id: "3",
          init: {
            x: this.canvasWidth / 2 + 0 * this.BALL_RADIUS,
            y: (this.canvasHeight * 2) / 3,
          },
        },
        {
          id: "4",
          init: {
            x: this.canvasWidth / 2 + 2 * this.BALL_RADIUS,
            y: (this.canvasHeight * 2) / 3,
          },
        },
        {
          id: "5",
          init: {
            x: this.canvasWidth / 2 + 4 * this.BALL_RADIUS,
            y: (this.canvasHeight * 2) / 3,
          },
        },
        {
          id: "6",
          init: {
            x: this.canvasWidth / 2 + 6 * this.BALL_RADIUS,
            y: (this.canvasHeight * 2) / 3,
          },
        },
      ],
      anchors = balls.map((ball) => ({
        id: "a" + ball.id,
        fx: ball.init.x,
        fy: ball.init.y - this.WIRE_LENGTH,
      })),
      wires = balls.map((ball) => ({
        source: ball.id,
        target: "a" + ball.id,
      }))

    // swing it
    balls[0].init.x -= this.WIRE_LENGTH
    balls[0].init.y -= this.WIRE_LENGTH

    let init = false

    d3.forceSimulation()
      .alphaDecay(0)
      .velocityDecay(0)
      .nodes([...balls, ...anchors])
      .force("gravity", forceConstant().strength(this.GRAVITY).direction(90))
      .force(
        "wires",
        d3
          .forceLink(wires)
          .id((node) => node.id)
          .distance(this.WIRE_LENGTH)
          .strength(0.4)
      )
      .force(
        "bounce",
        forceBounce().radius((node) => this.BALL_RADIUS)
      )
      .force("init", () => {
        if (!init) {
          balls.forEach((ball) => {
            ball.x = ball.init.x
            ball.y = ball.init.y
            ball.vx = 0
            ball.vy = 0
          })
          init = true
        }
      })
      .on("tick", () => {
        ballDigest()
        wireDigest()
      })

    const ballDigest = () => {
      const ball = ballsG.selectAll("circle.ball").data(balls)

      ball.exit().remove()

      ball
        .merge(
          ball
            .enter()
            .append("circle")
            .classed("ball", true)
            .attr("r", this.BALL_RADIUS)
            .attr("fill", "white")
            .call(
              d3
                .drag()
                .on("start", (d) => {
                  d.fx = d.x
                  d.fy = d.y
                })
                .on("drag", (d) => {
                  d.fx = d3.event.x
                  d.fy = d3.event.y
                })
                .on("end", (d) => {
                  d.fx = null
                  d.fy = null
                })
            )
        )
        .attr("cx", (d) => d.x)
        .attr("cy", (d) => d.y)
    }

    const wireDigest = () => {
      const wire = wiresG.selectAll("line.wire").data(wires)

      wire.exit().remove()

      wire
        .merge(
          wire
            .enter()
            .append("line")
            .classed("wire", true)
            .attr("stroke", "slategrey")
            .attr("stroke-width", 1)
        )
        .attr("x1", (d) => d.source.x)
        .attr("y1", (d) => d.source.y)
        .attr("x2", (d) => d.target.x)
        .attr("y2", (d) => d.target.y)
    }
  }

  render() {
    return <svg id="newtonsCradle" className="newtons-cradle-animation" />
  }
}

export default NewtonsCradleAnimation
