/**
 * This component stacks multiple content blurbs and images and alternates the
 * image and content order for each stacked item. By default, the first row has
 * the image on the left and text on the right.
 */

import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import loadable from "@loadable/component"
import { IsVisible } from "../components"
const Leadership = loadable(() => import("../components/LeadershipAnimation"))
const ResultsAnimation = loadable(() =>
  import("../components/ResultsAnimation")
)

const StackedContent = (props) => {
  const { content, darkMode, firstImageRight, images } = props
  return (
    <div className="container stacked-content">
      <div className="eleven columns offset-by-one-half">
        {content.map((item, i) => {
          const img = images.find((image) => {
            const image_split_array =
              image.childImageSharp.gatsbyImageData.images.fallback.src.split(
                "/"
              )
            const file_name = image_split_array[image_split_array.length - 1]
            return file_name === item.primaryImage
          })
          let reverse = i % 2 !== 0
          if (firstImageRight) {
            reverse = !reverse
          }

          let imageAnimation
          switch (item.heading) {
            case "Leadership":
              imageAnimation = (
                <IsVisible margin="350px" placeholderHeight="450px">
                  <Leadership />
                </IsVisible>
              )
              break
            case "Results":
              imageAnimation = (
                <IsVisible margin="350px" placeholderHeight="300px">
                  <ResultsAnimation />
                </IsVisible>
              )
              break
            default:
              imageAnimation = (
                <GatsbyImage
                  alt={item.heading}
                  image={img.childImageSharp.gatsbyImageData}
                />
              )
          }

          return (
            <div
              className={`container desk-flex ${reverse ? "row-reverse" : ""}`}
              key={i}
            >
              <div className="six columns image-container">
                {imageAnimation}
              </div>
              <div className="six columns flex align-center content-container">
                <div>
                  <h3 className={darkMode ? "" : "purple-text"}>
                    {item.heading}
                  </h3>
                  <p className={darkMode ? "" : "black-text"}>
                    {item.description}
                  </p>
                </div>
              </div>
            </div>
          )
        })}
      </div>
    </div>
  )
}

StackedContent.propTypes = {
  content: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  darkMode: PropTypes.bool,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      childImageSharp: PropTypes.object,
    })
  ),
}

export default StackedContent
