import { graphql, useStaticQuery } from "gatsby"
import _ from "lodash"

const useContentfulImage = (contentful_id) => {
  const { allContentfulAsset } = useStaticQuery(
    graphql`
      query CONTENTFUL_IMAGE_QUERY {
        allContentfulAsset {
          nodes {
            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
            contentful_id
          }
        }
      }
    `
  )

  return _.get(
    _.find(allContentfulAsset.nodes, { contentful_id }),
    "gatsbyImageData"
  )
}

export default useContentfulImage
