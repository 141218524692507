import React, { useEffect } from "react"
import { disableBodyScroll, enableBodyScroll } from "body-scroll-lock"
import { Link } from "gatsby"

const HamburgerMenu = (props) => {
  const open = props.hamburgerMenuOpen
  const hamburgerStyle = open
    ? "hamburgerMenu open"
    : "hamburgerMenu mobile-only"
  const navMenuStyle = open ? "navMenuContent" : "navMenuContent closed"
  let isMobileIosSafari

  useEffect(() => {
    isMobileIosSafari =
      navigator.userAgent.indexOf("Safari") != -1 &&
      navigator.userAgent.indexOf("iPhone") != -1
    if (isMobileIosSafari) {
      open
        ? (document.ontouchmove = function (e) {
            e.preventDefault()
          })
        : (document.ontouchmove = function () {
            return true
          })
    }
  })

  const navLinkStyle = (path) => {
    if (
      (props.page === "Home" && path.length < 2) ||
      (path.length > 1 && window.location.href.indexOf(path) > -1)
    ) {
      return "link current-page"
    }
    return "link"
  }

  function handleMenuOpen(setFalse) {
    if (setFalse) {
      props.setHamburgerMenu(false)
      enableBodyScroll()
      if (props.switchLogo) {
        props.switchLogo(false)
      }
    } else {
      props.setHamburgerMenu(!open)
      open ? enableBodyScroll() : disableBodyScroll()
    }
  }

  return (
    <>
      <div className={hamburgerStyle} onClick={() => handleMenuOpen()}>
        <div></div>
        <div></div>
        <div></div>
      </div>
      <div className={navMenuStyle}>
        {props.showLinks && (
          <div className="nav-links">
            <Link
              to="/"
              onClick={() => handleMenuOpen()}
              className={navLinkStyle("/")}
            >
              Corporate
            </Link>
            <Link
              to="/startups"
              onClick={() => handleMenuOpen()}
              className={navLinkStyle("/startups")}
            >
              Startups
            </Link>
            <Link
              to="/our-work"
              onClick={() => handleMenuOpen()}
              className={navLinkStyle("/our-work")}
            >
              Our Work
            </Link>
            <Link
              to="/contact"
              onClick={() => handleMenuOpen()}
              className={navLinkStyle("/contact")}
            >
              Contact
            </Link>
            <Link
              to="/blog"
              onClick={() => handleMenuOpen()}
              className={navLinkStyle("/blog")}
            >
              Blog
            </Link>
          </div>
        )}
        <div className="align-center justify-center">
          <p>New York, NY</p>
          <p>Los Angeles, CA</p>
          <p>Design & Development</p>
        </div>
      </div>
    </>
  )
}

export default HamburgerMenu
