/** This component is meant to wrap animations so that they load once they are on or
 * near the screen and they are destroyed when not on the screen. The margin prop
 * provides cushion on how close to the viewport the child element should be before it
 * is mounted or destroyed. The placeholderHeight and placeholerWidth props are the dimensions
 * for the div that will be rendered when the animation is off-screen.
 */

import React from "react"

class IsVisible extends React.Component {
  ref = React.createRef()

  state = {
    load: false,
  }

  async componentDidMount() {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          const { isIntersecting, intersectionRatio } = entry
          if (isIntersecting) {
            this.setState({
              load: true,
            })
          } else if (
            !isIntersecting &&
            intersectionRatio === 0 &&
            this.state.load
          ) {
            this.setState({
              load: false,
            })
          }
        })
      },
      {
        root: null,
        rootMargin: this.props.margin || "50px",
        threshold: 0,
      }
    )

    if (this.ref.current) {
      observer.observe(this.ref.current)
    }
  }

  render() {
    const { load } = this.state
    const { placeholderHeight, placeholderWidth } = this.props
    return (
      <div
        ref={this.ref}
        style={{
          height: `${placeholderHeight || "100%"}`,
          width: `${placeholderWidth || "100%"}`,
        }}
      >
        {load ? this.props.children : null}
      </div>
    )
  }
}

export default IsVisible
