import React, { useEffect, useState } from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { HamburgerMenu } from "../components"
import LogotypeWhite from "../images/futureFoundryLogos/FF_Logo_Logotype_White"
import LogotypeBlack from "../images/futureFoundryLogos/FF_Logo_Logotype_Black"
import { isBrowser } from "../components/shared"

const Header = ({ page, siteTitle }) => {
  useEffect(() => {
    const hasVisitedHome = window.sessionStorage.getItem("hasVisitedHome")
    const headerShouldFadeIn = page === "Home" && !hasVisitedHome
    if (headerShouldFadeIn) {
      document.getElementById("header").classList.add("fade-in")
    }
    if (page === "Home") {
      window.sessionStorage.setItem("hasVisitedHome", true)
    }

    const icon =
      document.querySelector("link[rel*='icon']") ||
      document.createElement("link")
    icon.type = "image/x-icon"
    icon.rel = "shortcut icon"
    const userDarkMode = window.matchMedia("(prefers-color-scheme: dark)")
    icon.href = userDarkMode.matches
      ? "/dark-mode-favicon.png"
      : "/light-mode-favicon.png"
    window.sessionStorage.setItem("hasVisitedSite", true)
    document.getElementsByTagName("head")[0].appendChild(icon)

    isBrowser() ? setIsBrowser(true) : setIsBrowser(false)
  })

  const [hamburgerMenuOpen, setHamburgerMenu] = useState(false)
  const [showLinks, setIsBrowser] = useState(false)

  // TODO: Verify usefulness of switch logo, is this needed
  const switchLogo = (bool) => {
    setHamburgerMenu(bool)
  }
  const isContactPage = page === "Contact" || page === "Call with Ricky"

  const LinkStyle = (path) => {
    if (page === "Home" && path.length < 2) {
      return "caption desktop-only current-page"
    }
    if (path.length > 1 && window.location.href.indexOf(path) > -1) {
      if (isContactPage) {
        return "caption desktop-only current-page contact-page"
      }
      return "caption desktop-only current-page"
    }
    return "caption desktop-only"
  }

  return (
    <header id="header" className={`${isContactPage ? "contact-header" : ""}`}>
      <div className="row flex align-center">
        <div className="three columns flex">
          <Link to="/" className="flex align-center">
            {!isContactPage || hamburgerMenuOpen ? (
              <LogotypeWhite />
            ) : (
              <LogotypeBlack />
            )}
          </Link>
        </div>
        {showLinks && (
          <div className="nine columns flex align-center flex-end header-links">
            <Link to="/" className={LinkStyle("/")}>
              Corporate
            </Link>
            <Link to="/startups" className={LinkStyle("/startups")}>
              Startups
            </Link>
            <Link to="/our-work" className={LinkStyle("/our-work")}>
              Our Work
            </Link>
            <Link to="/contact" className={LinkStyle("/contact")}>
              Contact
            </Link>
            <Link to="/blog" className={LinkStyle("/blog")}>
              Blog
            </Link>
          </div>
        )}
        <div className="hamburger-container">
          <HamburgerMenu
            page={page}
            siteTitle={siteTitle}
            setHamburgerMenu={setHamburgerMenu}
            hamburgerMenuOpen={hamburgerMenuOpen}
            showLinks={showLinks}
            switchLogo={switchLogo}
          />
        </div>
      </div>
    </header>
  )
}

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `Future Foundry`,
}

export default Header
