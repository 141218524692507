import { useStaticQuery, graphql } from "gatsby"

export const useContentfulOurWork = () => {
  const { contentfulOurWorkSection } = useStaticQuery(graphql`
    query getOurWork {
      contentfulOurWorkSection {
        title
        shortDescription
        projects {
          id
          client
          details {
            details
          }
          industry
          isMobile
          slug
          subtitle
          tertiaryImage {
            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          }
          type
          secondaryImage {
            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          }
          primaryImage {
            gatsbyImageData(layout: FULL_WIDTH, quality: 90)
          }
        }
      }
    }
  `)
  return contentfulOurWorkSection
}
