import React, { useEffect, useRef, useState } from "react"
import { GatsbyImage } from "gatsby-plugin-image"

const LogoFadeMobile = ({ images }) => {
  const [selectedIndex, setSelectedIndex] = useState(0)

  useInterval(() => {
    const nextIndex = selectedIndex < images.length - 1 ? selectedIndex + 1 : 0
    setSelectedIndex(nextIndex)
  }, 3000) // note that this needs to match .fade animation timing

  const image = images[selectedIndex]

  return (
    <div className="logo-bar">
      <div className="logos">
        <GatsbyImage
          alt={image.name ? image.name.split(".")[0] : "logo"}
          className={`${image.name} fade`}
          image={image.childImageSharp.gatsbyImageData}
          key={image.id}
        />
      </div>
    </div>
  )
}

function useInterval(callback, delay) {
  const savedCallback = useRef()

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback
  }, [callback])

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current()
    }
    if (delay !== null) {
      const id = setInterval(tick, delay)
      return () => clearInterval(id)
    }
  }, [delay])
}

export default LogoFadeMobile
