/** Orthographic shading animation adapted from https://bl.ocks.org/mbostock/835cf2925ba217821434 */

import React from "react"
import * as d3 from "d3-v3" // v3
import WidthAwareComponent from "./WidthAwareComponent"

class LeadershipAnimation extends WidthAwareComponent {
  constructor(props) {
    super(props)
    this.canvasHeight = 0
    this.canvasWidth = 0
    this.color = "#40C4A0"
    this.nodes = (function () {
      return d3.range(200).map(function () {
        return { radius: 8 }
      })
    })()
  }

  determineScreenSizeAndDraw = (initial, prevWidth) => {
    const { width } = this.state
    const shouldDrawDesktop = width > 767 && (prevWidth < 768 || initial)
    const shouldDrawMobile = width < 768

    if (!shouldDrawDesktop && !shouldDrawMobile) {
      return
    }

    if (shouldDrawDesktop) {
      this.canvasWidth = 450
      this.canvasHeight = 450
    } else if (shouldDrawMobile) {
      const maxWidth = 300
      const mobileWidth = width * 0.84 - 2
      if (maxWidth < mobileWidth) {
        this.canvasWidth = maxWidth
        this.canvasHeight = maxWidth
      } else {
        this.canvasWidth = mobileWidth
        this.canvasHeight = mobileWidth
      }
    }

    if (this.force) {
      this.force.stop()
    }

    this.setState({}, () => {
      this.force = d3.layout
        .force()
        .charge(0.3)
        .gravity(0)
        .friction(1)
        .nodes(this.nodes)
        .size([this.canvasWidth, this.canvasHeight])
        .start()
        .on("tick", () => {
          this.tick(this.nodes)
        })
    })
  }

  tick(nodes) {
    const stroke = d3.scale
      .linear()
      .domain([0, 1])
      .range([this.color, this.color]) // todo: fix this. not using range

    const collide = (node) => {
      const r = node.radius + 16
      const nx1 = node.x - r
      const nx2 = node.x + r
      const ny1 = node.y - r
      const ny2 = node.y + r

      return function (quad, x1, y1, x2, y2) {
        if (quad.point && quad.point !== node) {
          let x = node.x - quad.point.x,
            y = node.y - quad.point.y,
            l = Math.sqrt(x * x + y * y)
          const r = node.radius + quad.point.radius
          if (l < r) {
            l = (l - r) / (l * 2)
            node.x -= x *= l
            node.y -= y *= l
            quad.point.x += x
            quad.point.y += y
          }
        }
        return x1 > nx2 || x2 < nx1 || y1 > ny2 || y2 < ny1
      }
    }

    const canvas = document.querySelector("#leadership-canvas")

    if (canvas) {
      const context = canvas.getContext("2d")
      const q = d3.geom.quadtree(nodes)
      context.clearRect(0, 0, this.canvasWidth, this.canvasHeight)
      context.lineWidth = 1.5

      nodes.forEach((node) => {
        q.visit(collide(node))
      })

      nodes.forEach((node) => {
        let vx, vy
        context.beginPath()
        context.arc(node.x, node.y, node.radius - 0.75, 0, 2 * Math.PI) // tau
        context.strokeStyle = stroke(
          (vx = node.x - node.px) * vx + (vy = node.y - node.py) * vy
        )
        context.stroke()
      })
      this.force.resume()
    }
  }

  componentWillUnmount() {
    this.force.stop()
    window.removeEventListener("resize", this.updateDimensions)
  }

  render() {
    return (
      <div className="canvas-container">
        <canvas
          id="leadership-canvas"
          resize="true"
          height={this.canvasWidth}
          width={this.canvasWidth}
        />
      </div>
    )
  }
}

export default LeadershipAnimation
