import React from "react"

const LogotypeBlack = () => (
  <svg
    width="815px"
    height="142px"
    viewBox="0 0 815 142"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
  >
    <title>FF_Logo_Logotype_Black</title>
    <desc>Created with Sketch.</desc>
    <g
      id="FF_Logo_Logotype_Black"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="FF_77-Copy"
        transform="translate(179.000000, 24.000000)"
        fill="#1B1C1D"
      >
        <g id="Logotype-black" transform="translate(0.574200, 0.737000)">
          <polygon
            id="Fill0"
            points="40.099 16.1876 11.91 16.1876 11.91 30.0496 33.679 30.0496 33.679 41.5856 11.91 41.5856 11.91 69.7746 -2.84217094e-14 69.7746 -2.84217094e-14 4.6516 40.099 4.6516"
          ></polygon>
          <path
            d="M84.475,69.775 L73.684,69.775 L73.497,66.984 C70.271,69.465 66.829,70.706 63.17,70.706 C50.02,70.706 43.447,63.233 43.447,48.284 L43.447,23.258 L54.983,23.258 L54.983,48.284 C54.983,52.626 55.711,55.836 57.17,57.913 C58.626,59.992 60.967,61.029 64.194,61.029 C67.79,61.029 70.706,59.728 72.939,57.122 L72.939,23.258 L84.475,23.258 L84.475,69.775 Z"
            id="Fill2"
          ></path>
          <path
            d="M119.3627,61.216 L118.3397,70.706 L110.0587,70.706 C108.0127,70.706 106.2137,70.38 104.6637,69.728 C103.1117,69.078 101.8097,68.209 100.7557,67.124 C99.7007,66.039 98.9097,64.751 98.3837,63.262 C97.8547,61.774 97.5917,60.193 97.5917,58.518 L97.5917,32.748 L90.9867,32.748 L92.0097,23.258 L97.5917,23.258 L97.5917,13.025 L109.1287,11.815 L109.1287,23.258 L119.3627,23.258 L119.3627,32.748 L109.1287,32.748 L109.1287,53.774 C109.1287,55.573 109.1897,56.968 109.3147,57.96 C109.4377,58.952 109.7327,59.697 110.1987,60.193 C110.6637,60.69 111.3607,60.984 112.2917,61.077 C113.2217,61.169 114.5247,61.216 116.1997,61.216 L119.3627,61.216 Z"
            id="Fill4"
          ></path>
          <path
            d="M167.0873,69.775 L156.2953,69.775 L156.1093,66.984 C152.8833,69.465 149.4413,70.706 145.7823,70.706 C132.6323,70.706 126.0593,63.233 126.0593,48.284 L126.0593,23.258 L137.5953,23.258 L137.5953,48.284 C137.5953,52.626 138.3233,55.836 139.7823,57.913 C141.2383,59.992 143.5793,61.029 146.8063,61.029 C150.4023,61.029 153.3183,59.728 155.5513,57.122 L155.5513,23.258 L167.0873,23.258 L167.0873,69.775 Z"
            id="Fill6"
          ></path>
          <path
            d="M204.0209,22.5143 L201.7889,33.3983 L197.5089,33.3983 C195.6479,33.3983 193.9409,33.8343 192.3919,34.7023 C190.8409,35.5703 189.7249,36.6873 189.0419,38.0503 L189.0419,69.7753 L177.5059,69.7753 L177.5059,23.2583 L188.2979,23.2583 L188.5779,28.1893 C192.1739,24.4063 196.2999,22.5143 200.9519,22.5143 L204.0209,22.5143 Z"
            id="Fill8"
          ></path>
          <path
            d="M217.5111,41.2135 L236.3981,41.2135 C236.3981,38.7945 235.7111,36.6555 234.3401,34.7945 C232.9681,32.9335 230.8491,32.0045 227.9821,32.0045 C225.1151,32.0045 222.8081,32.8405 221.0641,34.5155 C219.3181,36.1905 218.1341,38.4225 217.5111,41.2135 M247.3721,49.0285 L217.2321,49.0285 C217.8541,53.0605 219.4871,56.0995 222.1331,58.1455 C224.7771,60.1925 227.7781,61.2155 231.1391,61.2155 C232.1971,61.2155 233.3481,61.1235 234.5921,60.9375 C235.8361,60.7505 237.0961,60.4875 238.3721,60.1465 C239.6471,59.8065 240.9071,59.4015 242.1531,58.9365 C243.3971,58.4715 244.5161,57.9605 245.5131,57.4005 L246.0731,67.1695 C241.5441,69.5285 236.3651,70.7055 230.5361,70.7055 C226.4431,70.7055 222.8281,70.0855 219.6981,68.8445 C216.5651,67.6055 213.9291,65.9145 211.7901,63.7745 C209.6501,61.6345 208.0351,59.0915 206.9521,56.1465 C205.8651,53.2015 205.3241,50.0215 205.3241,46.6095 C205.3241,43.2605 205.8351,40.1305 206.8591,37.2135 C207.8821,34.2975 209.3551,31.7395 211.2781,29.5375 C213.1991,27.3375 215.5401,25.6155 218.3021,24.3755 C221.0621,23.1345 224.1781,22.5145 227.6521,22.5145 C230.5661,22.5145 233.2801,22.9955 235.7921,23.9555 C238.3041,24.9185 240.4741,26.2815 242.3041,28.0495 C244.1331,29.8175 245.5621,31.9425 246.5841,34.4225 C247.6081,36.9035 248.1181,39.6645 248.1181,42.7015 C248.1181,44.4395 247.8691,46.5485 247.3721,49.0285"
            id="Fill10"
          ></path>
          <polygon
            id="Fill12"
            points="318.0804 16.1876 289.8914 16.1876 289.8914 30.0496 311.6614 30.0496 311.6614 41.5856 289.8914 41.5856 289.8914 69.7746 277.9824 69.7746 277.9824 4.6516 318.0804 4.6516"
          ></polygon>
          <path
            d="M350.6673,36.004 C348.6373,33.338 345.8073,32.004 342.1773,32.004 C338.4833,32.004 335.6223,33.338 333.5933,36.004 C331.5623,38.671 330.5473,42.207 330.5473,46.609 C330.5473,51.014 331.5623,54.55 333.5933,57.216 C335.6223,59.883 338.4833,61.216 342.1773,61.216 C345.8683,61.216 348.7293,59.852 350.7613,57.122 C352.7893,54.395 353.8053,50.889 353.8053,46.609 C353.8053,42.207 352.7593,38.671 350.6673,36.004 M359.2953,63.728 C355.0763,68.379 349.3713,70.706 342.1773,70.706 C338.5793,70.706 335.3383,70.085 332.4553,68.844 C329.5703,67.606 327.1053,65.9 325.0593,63.728 C323.0123,61.558 321.4303,59.015 320.3133,56.099 C319.1973,53.185 318.6393,50.022 318.6393,46.609 C318.6393,43.261 319.1973,40.114 320.3133,37.167 C321.4303,34.222 323.0123,31.663 325.0593,29.491 C327.1053,27.321 329.5703,25.616 332.4553,24.375 C335.3383,23.135 338.5793,22.514 342.1773,22.514 C345.7733,22.514 349.0283,23.151 351.9453,24.421 C354.8593,25.693 357.3413,27.414 359.3883,29.585 C361.4343,31.755 363.0003,34.298 364.0863,37.213 C365.1703,40.13 365.7143,43.261 365.7143,46.609 C365.7143,53.37 363.5743,59.076 359.2953,63.728"
            id="Fill14"
          ></path>
          <path
            d="M413.9056,69.775 L403.1136,69.775 L402.9276,66.984 C399.7016,69.465 396.2596,70.706 392.6006,70.706 C379.4506,70.706 372.8776,63.233 372.8776,48.284 L372.8776,23.258 L384.4136,23.258 L384.4136,48.284 C384.4136,52.626 385.1416,55.836 386.6006,57.913 C388.0566,59.992 390.3976,61.029 393.6246,61.029 C397.2206,61.029 400.1366,59.728 402.3696,57.122 L402.3696,23.258 L413.9056,23.258 L413.9056,69.775 Z"
            id="Fill16"
          ></path>
          <path
            d="M465.3519,69.775 L453.8159,69.775 L453.8159,44.749 C453.8159,40.409 453.0849,37.198 451.6289,35.12 C450.1709,33.043 447.8299,32.004 444.6059,32.004 C441.0699,32.004 438.1549,33.338 435.8599,36.004 L435.8599,69.775 L424.3239,69.775 L424.3239,23.258 L435.1159,23.258 L435.3019,26.049 C438.5879,23.692 441.9999,22.514 445.5359,22.514 C458.7469,22.514 465.3519,29.927 465.3519,44.749 L465.3519,69.775 Z"
            id="Fill18"
          ></path>
          <path
            d="M496.096,32.004 C492.39,32.004 489.518,33.322 487.479,35.957 C485.441,38.594 484.422,42.144 484.422,46.609 C484.422,51.076 485.441,54.627 487.479,57.262 C489.518,59.899 492.39,61.216 496.096,61.216 C499.678,61.216 502.348,59.914 504.109,57.309 C505.869,54.703 506.751,51.137 506.751,46.609 C506.751,42.084 505.869,38.515 504.109,35.911 C502.348,33.306 499.678,32.004 496.096,32.004 L496.096,32.004 Z M517.728,69.775 L506.843,69.775 L506.657,67.17 C503.183,69.528 499.4,70.706 495.307,70.706 C491.647,70.706 488.407,70.085 485.585,68.844 C482.762,67.606 480.39,65.884 478.468,63.682 C476.545,61.48 475.073,58.921 474.048,56.006 C473.025,53.091 472.514,49.959 472.514,46.609 C472.514,43.261 473.04,40.114 474.095,37.167 C475.149,34.222 476.654,31.663 478.608,29.491 C480.561,27.321 482.949,25.616 485.771,24.375 C488.592,23.135 491.772,22.514 495.307,22.514 C499.461,22.514 503.09,23.537 506.192,25.584 L506.192,7.10542736e-14 L517.728,7.10542736e-14 L517.728,69.775 Z"
            id="Fill20"
          ></path>
          <path
            d="M554.5697,22.5143 L552.3377,33.3983 L548.0577,33.3983 C546.1967,33.3983 544.4907,33.8343 542.9407,34.7023 C541.3897,35.5703 540.2737,36.6873 539.5907,38.0503 L539.5907,69.7753 L528.0557,69.7753 L528.0557,23.2583 L538.8467,23.2583 L539.1267,28.1893 C542.7227,24.4063 546.8487,22.5143 551.5007,22.5143 L554.5697,22.5143 Z"
            id="Fill22"
          ></path>
          <polygon
            id="Fill24"
            points="610.305 23.2584 581.185 92.2894 569.649 92.2894 579.976 66.7044 560.624 23.2584 573.464 23.2584 585.371 53.2144 597.467 23.2584"
          ></polygon>
        </g>
      </g>
      <g id="FF_Logo_Black" transform="translate(1.000000, 1.000000)">
        <g id="Group53" transform="translate(0.987179, 0.000000)">
          <path
            d="M84.8149077,123.97075 C84.9136256,126.435785 79.6815744,128.506414 73.1661897,128.506414 C66.6508051,128.506414 61.4187538,126.435785 61.6161897,123.97075 C61.7149077,121.505715 66.9459718,119.533687 73.2649077,119.533687 C79.5818692,119.533687 84.7152026,121.505715 84.8149077,123.97075"
            id="FillA"
            fill="#1B1C1D"
          ></path>
          <path
            d="M115.417472,118.745073 C115.811356,121.11052 110.975164,123.082548 104.558497,123.082548 C98.1418308,123.082548 92.8110615,121.11052 92.7123436,118.745073 C92.5149077,116.378639 97.4508051,114.504227 103.571318,114.504227 C109.790549,114.504227 115.0226,116.378639 115.417472,118.745073"
            id="Fill3"
            fill="#1B1C1D"
          ></path>
          <path
            d="M123.511554,107.109319 C123.906426,109.27855 119.366387,111.053375 113.245874,111.053375 C107.124374,111.053375 101.991041,109.27855 101.793605,107.109319 C101.597156,104.941074 106.137195,103.26485 112.060272,103.26485 C117.983349,103.26485 123.117669,104.941074 123.511554,107.109319"
            id="Fill5"
            fill="#1B1C1D"
          ></path>
          <path
            d="M106.13759,97.348372 C106.433744,99.3204 101.892718,100.996624 96.068359,100.996624 C90.244,100.996624 85.4068205,99.3204 85.3081026,97.348372 C85.2093846,95.3763441 89.7504103,93.7977357 95.3773333,93.7977357 C101.102974,93.7977357 105.841436,95.3763441 106.13759,97.348372"
            id="Fill7"
            fill="#1B1C1D"
          ></path>
          <path
            d="M53.8170769,118.745073 C53.619641,121.11052 48.2888718,123.082548 41.8722051,123.082548 C35.4555385,123.082548 30.618359,121.11052 31.0132308,118.745073 C31.4081026,116.378639 36.7388718,114.504227 42.9581026,114.504227 C49.1773333,114.504227 54.0145128,116.378639 53.8170769,118.745073"
            id="Fill9"
            fill="#1B1C1D"
          ></path>
          <path
            d="M44.5376885,107.109319 C44.2415346,109.27855 39.1082013,111.053375 32.9876885,111.053375 C26.8671756,111.053375 22.32615,109.27855 22.8197397,107.109319 C23.3133295,104.941074 28.4466628,103.26485 34.3697397,103.26485 C40.2928167,103.26485 44.7351244,104.941074 44.5376885,107.109319"
            id="Fill11"
            fill="#1B1C1D"
          ></path>
          <path
            d="M61.4185564,97.348372 C61.3198385,99.3204 56.482659,100.996624 50.6583,100.996624 C44.833941,100.996624 40.2929154,99.3204 40.5890692,97.348372 C40.8852231,95.3763441 45.7224026,93.7977357 51.3493256,93.7977357 C56.9762487,93.7977357 61.5172744,95.3763441 61.4185564,97.348372"
            id="Fill13"
            fill="#1B1C1D"
          ></path>
          <path
            d="M80.3726,133.43609 C80.3726,135.014698 77.2136256,136.394131 73.1661897,136.394131 C69.1187538,136.394131 65.9587923,135.112313 65.9587923,133.43609 C66.0584974,131.858467 69.2174718,130.577635 73.1661897,130.577635 C77.1139205,130.577635 80.2728949,131.858467 80.3726,133.43609"
            id="Fill15"
            fill="#1B1C1D"
          ></path>
          <path
            d="M127.757215,125.350578 C128.053369,126.829599 125.189562,128.112403 121.240844,128.112403 C117.292126,128.112403 114.035421,126.829599 113.739267,125.350578 C113.541831,123.871557 116.404651,122.689326 120.253664,122.689326 C124.104651,122.689326 127.460074,123.772955 127.757215,125.350578"
            id="Fill17"
            fill="#1B1C1D"
          ></path>
          <path
            d="M140.392915,107.011112 C140.7868,108.29293 138.122403,109.377545 134.469838,109.377545 C130.817274,109.377545 127.6583,108.29293 127.362146,107.011112 C127.065992,105.729294 129.731377,104.644678 133.285223,104.644678 C136.838082,104.644678 139.998044,105.729294 140.392915,107.011112"
            id="Fill19"
            fill="#1B1C1D"
          ></path>
          <path
            d="M115.120528,92.0235021 C115.317964,93.2067189 112.752285,94.0951175 109.297156,94.0951175 C105.842028,94.0951175 102.978221,93.1091035 102.88049,92.0235021 C102.780785,90.8402853 105.348438,89.9528727 108.703862,89.9528727 C112.060272,89.9528727 114.924079,90.8402853 115.120528,92.0235021"
            id="Fill21"
            fill="#1B1C1D"
          ></path>
          <path
            d="M47.2027769,91.2348881 C47.104059,92.4181049 44.2412385,93.3055175 40.7861103,93.3055175 C37.3309821,93.3055175 34.7643154,92.4181049 34.9617513,91.2348881 C35.1591872,90.1502727 38.0220077,89.1642587 41.3784179,89.1642587 C44.7348282,89.1642587 47.4002128,90.0516713 47.2027769,91.2348881"
            id="Fill23"
            fill="#1B1C1D"
          ></path>
          <path
            d="M19.9570179,105.532091 C19.6608641,106.813909 16.5018897,107.898524 12.8493256,107.898524 C9.19676154,107.898524 6.63009487,106.813909 6.92624872,105.532091 C7.32112051,104.250273 10.4800949,103.264259 13.9352231,103.264259 C17.3903513,103.264259 20.2531718,104.250273 19.9570179,105.532091"
            id="Fill25"
            fill="#1B1C1D"
          ></path>
          <path
            d="M29.6311795,123.97075 C29.3350256,125.449771 25.9786154,126.731589 22.1286154,126.731589 C18.2786154,126.731589 15.3170769,125.548372 15.7119487,123.97075 C16.0081026,122.491729 19.3645128,121.308512 23.2145128,121.308512 C27.0645128,121.308512 29.9273333,122.491729 29.6311795,123.97075"
            id="Fill27"
            fill="#1B1C1D"
          ></path>
          <path
            d="M66.9463667,138.563559 C66.9463667,139.648175 64.6758538,140.535587 61.9117513,140.535587 C59.1476487,140.535587 57.0745718,139.648175 57.0745718,138.563559 C57.1732897,137.478944 59.3450846,136.591531 62.1091872,136.591531 C64.7745718,136.49293 66.9463667,137.380343 66.9463667,138.563559"
            id="Fill29"
            fill="#1B1C1D"
          ></path>
          <path
            d="M129.040351,131.563255 C129.237787,132.64787 127.263428,133.435695 124.598044,133.435695 C121.932659,133.435695 119.662146,132.549269 119.46471,131.563255 C119.267274,130.477653 121.241633,129.689828 123.907018,129.689828 C126.472697,129.689828 128.842915,130.477653 129.040351,131.563255"
            id="Fill31"
            fill="#1B1C1D"
          ></path>
          <path
            d="M151.449523,109.969154 C151.745677,110.856566 149.968754,111.546776 147.500805,111.546776 C145.032856,111.546776 142.861062,110.856566 142.663626,109.969154 C142.465203,109.081741 144.144395,108.391531 146.612344,108.391531 C149.080292,108.391531 151.152382,109.081741 151.449523,109.969154"
            id="Fill33"
            fill="#1B1C1D"
          ></path>
          <path
            d="M12.4543551,124.266357 C12.2569192,125.252371 9.88768846,126.041182 7.32102179,126.041182 C4.75435513,126.041182 2.8787141,125.252371 3.17486795,124.266357 C3.47102179,123.280343 5.74153462,122.491531 8.30820128,122.491531 C10.8748679,122.491531 12.750509,123.280343 12.4543551,124.266357"
            id="Fill36"
            fill="#1B1C1D"
          ></path>
          <path
            d="M8.60445385,101.785435 C8.40701795,102.574246 6.23522308,103.264456 3.86599231,103.264456 C1.49676154,103.264456 -0.18144359,102.574246 0.0159923077,101.785435 C0.312146154,100.996624 2.38522308,100.306414 4.6557359,100.306414 C6.92624872,100.306414 8.80188974,100.996624 8.60445385,101.785435"
            id="Fill38"
            fill="#1B1C1D"
          ></path>
          <path
            d="M46.6108641,86.6004252 C46.5121462,87.290635 44.6365051,87.8822434 42.4647103,87.8822434 C40.2929154,87.8822434 38.5159923,87.290635 38.7134282,86.6004252 C38.8121462,85.9102154 40.6877872,85.318607 42.8595821,85.318607 C45.0313769,85.318607 46.7095821,85.9102154 46.6108641,86.6004252"
            id="Fill40"
            fill="#1B1C1D"
          ></path>
          <path
            d="M125.38759,90.8400881 C125.585026,91.6298853 123.906821,92.2205077 121.636308,92.2205077 C119.365795,92.2205077 117.391436,91.6298853 117.292718,90.8400881 C117.194,90.0512769 118.872205,89.4606545 121.044,89.4606545 C123.215795,89.4606545 125.190154,90.0512769 125.38759,90.8400881"
            id="Fill42"
            fill="#1B1C1D"
          ></path>
          <g id="Group52" transform="translate(28.628205, 0.521601)">
            <path
              d="M24.9188782,18.852686 C36.9427244,19.2707559 46.8382115,13.705693 52.3150833,9.7360007 C53.7968397,8.66223147 55.3348654,7.67128741 56.9133654,6.76316853 C56.9577885,6.72865804 57.0012244,6.69710559 57.0456474,6.6625951 C57.0456474,6.6625951 52.9221987,8.22542727 43.0296731,7.71171399 C33.1381346,7.19701469 9.74889103,-2.50536294 0.094275641,11.3678538 C0.347980769,11.6360497 6.73996795,18.2226231 24.9188782,18.852686"
              id="Fill44"
              fill="#383838"
            ></path>
            <path
              d="M66.750609,49.1509238 C80.2029038,41.483679 89.2721218,27.0257559 89.2721218,10.4459308 C89.2721218,7.35379091 88.9552372,4.3356021 88.3560192,1.42193077 C88.3560192,1.42193077 83.9995962,0.102644056 76.931391,0.561140559 C69.847391,1.02062308 63.0398013,3.24112657 56.9133654,6.76316853 C55.3348654,7.67128741 53.7968397,8.66223147 52.3150833,9.7360007 C46.8382115,13.705693 36.9427244,19.2707559 24.9188782,18.852686 C6.73996795,18.2226231 0.347980769,11.6360497 0.094275641,11.3678538 C0.0932884615,11.3678538 0.0932884615,11.3688399 0.0923012821,11.3698259 L0.0923012821,11.3698259 C0.425967949,27.6686371 9.51887821,41.8149797 22.8625833,49.3027699 C33.0937115,55.0433434 39.6367372,65.6351056 39.6367372,77.3558538 L39.6367372,81.804749 C39.6367372,88.5855671 38.0345449,94.8004133 35.2724167,100.235322 C25.3532372,101.422483 18.0441603,104.421938 17.5870962,108.066245 C16.9957756,112.8987 29.1370962,117.039959 44.6368013,117.039959 C60.1355192,117.039959 72.2778269,112.996315 71.7842372,108.066245 C71.39825,104.369679 63.9035833,101.334728 53.7731474,100.183064 C51.2548526,94.9374692 49.8056731,89.001665 49.8056731,82.5649657 L49.8056731,77.7019448 C49.8056731,65.807658 56.4079295,55.0463014 66.750609,49.1509238"
              id="Fill50"
              fill="#1B1C1D"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default LogotypeBlack
