import React from "react"
import { Link } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"

const AboutPerson = (props) => {
  const { image, link, name, title, about } = props

  return (
    <div className="about-person container">
      <div className="ten columns offset-by-one desk-flex">
        <div className="one-half column image-container">
          <GatsbyImage
            alt={name || title}
            className="image"
            image={image.childImageSharp.gatsbyImageData}
          />
        </div>
        <div className="one-half column content-container">
          <div className="green-bar"></div>
          <h3>{title}</h3>
          <p className="small">{about}</p>
          {name && <h5>{name}</h5>}
          {link && (
            <Link to={link}>
              <button className="contact-button">Contact Us</button>
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

export default AboutPerson
