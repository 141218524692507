import React from "react"
import useNetlifyForm from "./hooks/NetlifyFormHook"

const ContactFormIndex = () => {
  const { inputs, handleInputChange, handleSubmit } = useNetlifyForm()

  return (
    <form
      onSubmit={handleSubmit}
      name="index-page-contact-form"
      id="index-page-contact-form"
      method="POST"
      data-netlify="true"
      data-netlify-honeypot="bot-field"
    >
      <input type="hidden" name="bot-field" />
      <input type="hidden" name="form-name" value="index-page-contact-form" />
      <div className="desk-flex">
        <div className="name-input">
          <label htmlFor="firstName">First name</label>
          <input
            type="text"
            name="firstName"
            id="firstName"
            placeholder="John"
            onChange={handleInputChange}
            value={inputs.firstName || ""}
            required
          />
        </div>
        <div className="name-input">
          <label htmlFor="lastName">Last name</label>
          <input
            type="text"
            name="lastName"
            id="lastName"
            placeholder="Elliot"
            onChange={handleInputChange}
            value={inputs.lasttName}
            required
          />
        </div>
      </div>
      <div>
        <label htmlFor="email">Email</label>
        <input
          type="email"
          name="email"
          id="email"
          placeholder="Email Address"
          onChange={handleInputChange}
          value={inputs.email || ""}
          required
        />
      </div>
      <div>
        <label htmlFor="companyName">Company Name</label>
        <input
          type="text"
          name="companyName"
          id="companyName"
          placeholder="Company Name"
          onChange={handleInputChange}
          value={inputs.companyName || ""}
          required
        />
      </div>
      <div>
        <label htmlFor="companyWebsite">Company website (Optional)</label>
        <input
          type="text"
          name="companyWebsite"
          id="companyWebsite"
          onChange={handleInputChange}
          value={inputs.companyWebsite || ""}
          placeholder="www.companywebsite.com"
        />
      </div>
      <div>
        <label htmlFor="pageTitle" className="hidden-label">
          Page Title
        </label>
        <textarea
          type="text"
          name="pageTitle"
          id="pageTitle"
          className="hidden"
          value={"Index Page"}
        />
      </div>
      <div className="button-container">
        <button className="blue-button" type="submit">
          Submit
        </button>
      </div>
    </form>
  )
}

export default ContactFormIndex
