import React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import { library } from "@fortawesome/fontawesome-svg-core"
import {
  faCaretLeft,
  faCaretRight,
  faLocationArrow,
} from "@fortawesome/free-solid-svg-icons"
import { fab } from "@fortawesome/free-brands-svg-icons"
import "../../scss/skeleton.scss"
import Footer from "./Footer"
import Header from "./Header"

library.add(faCaretLeft, faCaretRight, faLocationArrow, fab)

const Layout = ({ children, title, noFooter, noHero }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <section>
      <Header page={title} siteTitle={`${data.site.siteMetadata.title}`} />
      <main className={noHero ? "avoid-nav" : ""}>{children}</main>
      {noFooter ? null : <Footer />}
    </section>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  title: PropTypes.string,
}

export default Layout
