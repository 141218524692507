/**
 * This component showcases a project image, a date and caption along with
 * a right and left button to click through the array of projects. If
 * "projectToExclude" is included as props, the project with that id will be
 * excluded from the carousel.
 */

import React, { useState } from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import left from "../images/svgs/left.svg"
import right from "../images/svgs/right.svg"
import { useContentfulOurWork } from "./hooks/useContentfulOurWork"
import { GatsbyImage } from "gatsby-plugin-image"

const MobileProjectCarousel = ({ projectToExclude }) => {
  const { projects } = useContentfulOurWork()
  const [selectedIndex, setSelectedIndex] = useState(0)

  let projectsToRender = [...projects]
  if (projectToExclude) {
    projectsToRender = projectsToRender.filter((c) => c.id !== projectToExclude)
  }

  const selectedProject = projectsToRender[selectedIndex]
  const { client, description, primaryImage, slug } = selectedProject

  return (
    <section className="mobile-project-carousel dark">
      <div className="container flex-column center">
        <div className="image-container">
          <GatsbyImage
            key={primaryImage.id}
            image={primaryImage.gatsbyImageData}
            className="background-image"
          />
          <img
            alt="left button"
            className="carousel-button"
            onClick={() => {
              const previous =
                selectedIndex > 0
                  ? selectedIndex - 1
                  : projectsToRender.length - 1
              setSelectedIndex(previous)
            }}
            src={left}
          />
          <Link
            to={`/our-work/${slug}`}
            className="link-area"
            aria-label={`${slug}`}
          ></Link>
          <img
            alt="right button"
            className="carousel-button"
            onClick={() => {
              const next =
                selectedIndex < projectsToRender.length - 1
                  ? selectedIndex + 1
                  : 0
              setSelectedIndex(next)
            }}
            src={right}
          />
        </div>
        <div className="content-container">
          <Link to={`/our-work/${slug}`}>
            <p className="small white-text">{client}</p>
          </Link>
          <Link to={`/our-work/${slug}`}>
            <p className="white-text">{description}</p>
          </Link>
        </div>
      </div>
    </section>
  )
}

MobileProjectCarousel.propTypes = {
  projects: PropTypes.arrayOf(
    PropTypes.shape({
      heading: PropTypes.string,
      description: PropTypes.string,
      image: PropTypes.string,
    })
  ),
  projectToExclude: PropTypes.string,
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      childImageSharp: PropTypes.object,
    })
  ),
}

export default MobileProjectCarousel
