import React from "react"

const Arrow = (props) => {
  const { children } = props

  return (
    <div className="arrow-container">
      <div className="arrow-text">{children}</div>
      <div className={`arrow`}>
        <div className="arrow-line"></div>
        <div className="arrow-head"></div>
      </div>
    </div>
  )
}

export default Arrow
