import React from "react"

const LogotypeWhite = () => (
  <svg
    width="815px"
    height="142px"
    viewBox="0 0 815 142"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <title>FF_Logo_Logotype_White</title>
    <desc>Created with Sketch.</desc>
    <g
      id="FF_Logo_Logotype_White"
      stroke="none"
      strokeWidth="1"
      fill="none"
      fillRule="evenodd"
    >
      <g
        id="FF_77-Copy-5"
        transform="translate(179.000000, 24.000000)"
        fill="#F0F0F0"
      >
        <g id="Logotype-white" transform="translate(0.574200, 0.737000)">
          <polygon
            id="Fill1"
            points="40.099 16.1876 11.91 16.1876 11.91 30.0496 33.679 30.0496 33.679 41.5856 11.91 41.5856 11.91 69.7746 -2.84217094e-14 69.7746 -2.84217094e-14 4.6516 40.099 4.6516"
          ></polygon>
          <path
            d="M84.475,69.775 L73.684,69.775 L73.497,66.984 C70.271,69.465 66.829,70.706 63.17,70.706 C50.02,70.706 43.447,63.233 43.447,48.284 L43.447,23.258 L54.983,23.258 L54.983,48.284 C54.983,52.626 55.711,55.836 57.17,57.913 C58.626,59.992 60.967,61.029 64.194,61.029 C67.79,61.029 70.706,59.728 72.939,57.122 L72.939,23.258 L84.475,23.258 L84.475,69.775 Z"
            id="Fill-2"
          ></path>
          <path
            d="M119.3627,61.216 L118.3397,70.706 L110.0587,70.706 C108.0127,70.706 106.2137,70.38 104.6637,69.728 C103.1117,69.078 101.8097,68.209 100.7557,67.124 C99.7007,66.039 98.9097,64.751 98.3837,63.262 C97.8547,61.774 97.5917,60.193 97.5917,58.518 L97.5917,32.748 L90.9867,32.748 L92.0097,23.258 L97.5917,23.258 L97.5917,13.025 L109.1287,11.815 L109.1287,23.258 L119.3627,23.258 L119.3627,32.748 L109.1287,32.748 L109.1287,53.774 C109.1287,55.573 109.1897,56.968 109.3147,57.96 C109.4377,58.952 109.7327,59.697 110.1987,60.193 C110.6637,60.69 111.3607,60.984 112.2917,61.077 C113.2217,61.169 114.5247,61.216 116.1997,61.216 L119.3627,61.216 Z"
            id="Fill-4"
          ></path>
          <path
            d="M167.0873,69.775 L156.2953,69.775 L156.1093,66.984 C152.8833,69.465 149.4413,70.706 145.7823,70.706 C132.6323,70.706 126.0593,63.233 126.0593,48.284 L126.0593,23.258 L137.5953,23.258 L137.5953,48.284 C137.5953,52.626 138.3233,55.836 139.7823,57.913 C141.2383,59.992 143.5793,61.029 146.8063,61.029 C150.4023,61.029 153.3183,59.728 155.5513,57.122 L155.5513,23.258 L167.0873,23.258 L167.0873,69.775 Z"
            id="Fill-6"
          ></path>
          <path
            d="M204.0209,22.5143 L201.7889,33.3983 L197.5089,33.3983 C195.6479,33.3983 193.9409,33.8343 192.3919,34.7023 C190.8409,35.5703 189.7249,36.6873 189.0419,38.0503 L189.0419,69.7753 L177.5059,69.7753 L177.5059,23.2583 L188.2979,23.2583 L188.5779,28.1893 C192.1739,24.4063 196.2999,22.5143 200.9519,22.5143 L204.0209,22.5143 Z"
            id="Fill-8"
          ></path>
          <path
            d="M217.5111,41.2135 L236.3981,41.2135 C236.3981,38.7945 235.7111,36.6555 234.3401,34.7945 C232.9681,32.9335 230.8491,32.0045 227.9821,32.0045 C225.1151,32.0045 222.8081,32.8405 221.0641,34.5155 C219.3181,36.1905 218.1341,38.4225 217.5111,41.2135 M247.3721,49.0285 L217.2321,49.0285 C217.8541,53.0605 219.4871,56.0995 222.1331,58.1455 C224.7771,60.1925 227.7781,61.2155 231.1391,61.2155 C232.1971,61.2155 233.3481,61.1235 234.5921,60.9375 C235.8361,60.7505 237.0961,60.4875 238.3721,60.1465 C239.6471,59.8065 240.9071,59.4015 242.1531,58.9365 C243.3971,58.4715 244.5161,57.9605 245.5131,57.4005 L246.0731,67.1695 C241.5441,69.5285 236.3651,70.7055 230.5361,70.7055 C226.4431,70.7055 222.8281,70.0855 219.6981,68.8445 C216.5651,67.6055 213.9291,65.9145 211.7901,63.7745 C209.6501,61.6345 208.0351,59.0915 206.9521,56.1465 C205.8651,53.2015 205.3241,50.0215 205.3241,46.6095 C205.3241,43.2605 205.8351,40.1305 206.8591,37.2135 C207.8821,34.2975 209.3551,31.7395 211.2781,29.5375 C213.1991,27.3375 215.5401,25.6155 218.3021,24.3755 C221.0621,23.1345 224.1781,22.5145 227.6521,22.5145 C230.5661,22.5145 233.2801,22.9955 235.7921,23.9555 C238.3041,24.9185 240.4741,26.2815 242.3041,28.0495 C244.1331,29.8175 245.5621,31.9425 246.5841,34.4225 C247.6081,36.9035 248.1181,39.6645 248.1181,42.7015 C248.1181,44.4395 247.8691,46.5485 247.3721,49.0285"
            id="Fill-10"
          ></path>
          <polygon
            id="Fill-12"
            points="318.0804 16.1876 289.8914 16.1876 289.8914 30.0496 311.6614 30.0496 311.6614 41.5856 289.8914 41.5856 289.8914 69.7746 277.9824 69.7746 277.9824 4.6516 318.0804 4.6516"
          ></polygon>
          <path
            d="M350.6673,36.004 C348.6373,33.338 345.8073,32.004 342.1773,32.004 C338.4833,32.004 335.6223,33.338 333.5933,36.004 C331.5623,38.671 330.5473,42.207 330.5473,46.609 C330.5473,51.014 331.5623,54.55 333.5933,57.216 C335.6223,59.883 338.4833,61.216 342.1773,61.216 C345.8683,61.216 348.7293,59.852 350.7613,57.122 C352.7893,54.395 353.8053,50.889 353.8053,46.609 C353.8053,42.207 352.7593,38.671 350.6673,36.004 M359.2953,63.728 C355.0763,68.379 349.3713,70.706 342.1773,70.706 C338.5793,70.706 335.3383,70.085 332.4553,68.844 C329.5703,67.606 327.1053,65.9 325.0593,63.728 C323.0123,61.558 321.4303,59.015 320.3133,56.099 C319.1973,53.185 318.6393,50.022 318.6393,46.609 C318.6393,43.261 319.1973,40.114 320.3133,37.167 C321.4303,34.222 323.0123,31.663 325.0593,29.491 C327.1053,27.321 329.5703,25.616 332.4553,24.375 C335.3383,23.135 338.5793,22.514 342.1773,22.514 C345.7733,22.514 349.0283,23.151 351.9453,24.421 C354.8593,25.693 357.3413,27.414 359.3883,29.585 C361.4343,31.755 363.0003,34.298 364.0863,37.213 C365.1703,40.13 365.7143,43.261 365.7143,46.609 C365.7143,53.37 363.5743,59.076 359.2953,63.728"
            id="Fill-14"
          ></path>
          <path
            d="M413.9056,69.775 L403.1136,69.775 L402.9276,66.984 C399.7016,69.465 396.2596,70.706 392.6006,70.706 C379.4506,70.706 372.8776,63.233 372.8776,48.284 L372.8776,23.258 L384.4136,23.258 L384.4136,48.284 C384.4136,52.626 385.1416,55.836 386.6006,57.913 C388.0566,59.992 390.3976,61.029 393.6246,61.029 C397.2206,61.029 400.1366,59.728 402.3696,57.122 L402.3696,23.258 L413.9056,23.258 L413.9056,69.775 Z"
            id="Fill-16"
          ></path>
          <path
            d="M465.3519,69.775 L453.8159,69.775 L453.8159,44.749 C453.8159,40.409 453.0849,37.198 451.6289,35.12 C450.1709,33.043 447.8299,32.004 444.6059,32.004 C441.0699,32.004 438.1549,33.338 435.8599,36.004 L435.8599,69.775 L424.3239,69.775 L424.3239,23.258 L435.1159,23.258 L435.3019,26.049 C438.5879,23.692 441.9999,22.514 445.5359,22.514 C458.7469,22.514 465.3519,29.927 465.3519,44.749 L465.3519,69.775 Z"
            id="Fill-18"
          ></path>
          <path
            d="M496.096,32.004 C492.39,32.004 489.518,33.322 487.479,35.957 C485.441,38.594 484.422,42.144 484.422,46.609 C484.422,51.076 485.441,54.627 487.479,57.262 C489.518,59.899 492.39,61.216 496.096,61.216 C499.678,61.216 502.348,59.914 504.109,57.309 C505.869,54.703 506.751,51.137 506.751,46.609 C506.751,42.084 505.869,38.515 504.109,35.911 C502.348,33.306 499.678,32.004 496.096,32.004 L496.096,32.004 Z M517.728,69.775 L506.843,69.775 L506.657,67.17 C503.183,69.528 499.4,70.706 495.307,70.706 C491.647,70.706 488.407,70.085 485.585,68.844 C482.762,67.606 480.39,65.884 478.468,63.682 C476.545,61.48 475.073,58.921 474.048,56.006 C473.025,53.091 472.514,49.959 472.514,46.609 C472.514,43.261 473.04,40.114 474.095,37.167 C475.149,34.222 476.654,31.663 478.608,29.491 C480.561,27.321 482.949,25.616 485.771,24.375 C488.592,23.135 491.772,22.514 495.307,22.514 C499.461,22.514 503.09,23.537 506.192,25.584 L506.192,1.42108547e-14 L517.728,1.42108547e-14 L517.728,69.775 Z"
            id="Fill-20"
          ></path>
          <path
            d="M554.5697,22.5143 L552.3377,33.3983 L548.0577,33.3983 C546.1967,33.3983 544.4907,33.8343 542.9407,34.7023 C541.3897,35.5703 540.2737,36.6873 539.5907,38.0503 L539.5907,69.7753 L528.0557,69.7753 L528.0557,23.2583 L538.8467,23.2583 L539.1267,28.1893 C542.7227,24.4063 546.8487,22.5143 551.5007,22.5143 L554.5697,22.5143 Z"
            id="Fill-22"
          ></path>
          <polygon
            id="Fill-24"
            points="610.305 23.2584 581.185 92.2894 569.649 92.2894 579.976 66.7044 560.624 23.2584 573.464 23.2584 585.371 53.2144 597.467 23.2584"
          ></polygon>
        </g>
      </g>
      <g id="FF_Logo_White" transform="translate(1.000000, 1.000000)">
        <g id="Group-53" transform="translate(0.987179, 0.000000)">
          <path
            d="M84.8149077,124.117287 C84.9136256,126.585236 79.6815744,128.658313 73.1661897,128.658313 C66.6508051,128.658313 61.4187538,126.585236 61.6161897,124.117287 C61.7149077,121.649338 66.9459718,119.674979 73.2649077,119.674979 C79.5818692,119.674979 84.7152026,121.649338 84.8149077,124.117287"
            id="Fill-1"
            fill="#F0F0F0"
          ></path>
          <path
            d="M115.417472,118.885433 C115.811356,121.253677 110.975164,123.228036 104.558497,123.228036 C98.1418308,123.228036 92.8110615,121.253677 92.7123436,118.885433 C92.5149077,116.516203 97.4508051,114.639574 103.571318,114.639574 C109.790549,114.639574 115.0226,116.516203 115.417472,118.885433"
            id="Fill-3"
            fill="#F0F0F0"
          ></path>
          <path
            d="M123.511554,107.235926 C123.906426,109.407721 119.366387,111.184644 113.245874,111.184644 C107.124374,111.184644 101.991041,109.407721 101.793605,107.235926 C101.597156,105.065118 106.137195,103.386913 112.060272,103.386913 C117.983349,103.386913 123.117669,105.065118 123.511554,107.235926"
            id="Fill-5"
            fill="#F0F0F0"
          ></path>
          <path
            d="M106.13759,97.463441 C106.433744,99.4378 101.892718,101.116005 96.068359,101.116005 C90.244,101.116005 85.4068205,99.4378 85.3081026,97.463441 C85.2093846,95.4890821 89.7504103,93.9086077 95.3773333,93.9086077 C101.102974,93.9086077 105.841436,95.4890821 106.13759,97.463441"
            id="Fill-7"
            fill="#F0F0F0"
          ></path>
          <path
            d="M53.8170769,118.885433 C53.619641,121.253677 48.2888718,123.228036 41.8722051,123.228036 C35.4555385,123.228036 30.618359,121.253677 31.0132308,118.885433 C31.4081026,116.516203 36.7388718,114.639574 42.9581026,114.639574 C49.1773333,114.639574 54.0145128,116.516203 53.8170769,118.885433"
            id="Fill-9"
            fill="#F0F0F0"
          ></path>
          <path
            d="M44.5376885,107.235926 C44.2415346,109.407721 39.1082013,111.184644 32.9876885,111.184644 C26.8671756,111.184644 22.32615,109.407721 22.8197397,107.235926 C23.3133295,105.065118 28.4466628,103.386913 34.3697397,103.386913 C40.2928167,103.386913 44.7351244,105.065118 44.5376885,107.235926"
            id="Fill-11"
            fill="#F0F0F0"
          ></path>
          <path
            d="M61.4185564,97.463441 C61.3198385,99.4378 56.482659,101.116005 50.6583,101.116005 C44.833941,101.116005 40.2929154,99.4378 40.5890692,97.463441 C40.8852231,95.4890821 45.7224026,93.9086077 51.3493256,93.9086077 C56.9762487,93.9086077 61.5172744,95.4890821 61.4185564,97.463441"
            id="Fill-13"
            fill="#F0F0F0"
          ></path>
          <path
            d="M80.3726,133.593815 C80.3726,135.17429 77.2136256,136.555354 73.1661897,136.555354 C69.1187538,136.555354 65.9587923,135.272021 65.9587923,133.593815 C66.0584974,132.014328 69.2174718,130.731982 73.1661897,130.731982 C77.1139205,130.731982 80.2728949,132.014328 80.3726,133.593815"
            id="Fill-15"
            fill="#F0F0F0"
          ></path>
          <path
            d="M127.757215,125.498746 C128.053369,126.979515 125.189562,128.263836 121.240844,128.263836 C117.292126,128.263836 114.035421,126.979515 113.739267,125.498746 C113.541831,124.017977 116.404651,122.834349 120.253664,122.834349 C124.104651,122.834349 127.460074,123.919259 127.757215,125.498746"
            id="Fill-17"
            fill="#F0F0F0"
          ></path>
          <path
            d="M140.392915,107.137603 C140.7868,108.420936 138.122403,109.506833 134.469838,109.506833 C130.817274,109.506833 127.6583,108.420936 127.362146,107.137603 C127.065992,105.854269 129.731377,104.768372 133.285223,104.768372 C136.838082,104.768372 139.998044,105.854269 140.392915,107.137603"
            id="Fill-19"
            fill="#F0F0F0"
          ></path>
          <path
            d="M115.120528,92.1322769 C115.317964,93.3168923 112.752285,94.206341 109.297156,94.206341 C105.842028,94.206341 102.978221,93.2191615 102.88049,92.1322769 C102.780785,90.9476615 105.348438,90.0592 108.703862,90.0592 C112.060272,90.0592 114.924079,90.9476615 115.120528,92.1322769"
            id="Fill-21"
            fill="#F0F0F0"
          ></path>
          <path
            d="M47.2027769,91.3427308 C47.104059,92.5273462 44.2412385,93.4158077 40.7861103,93.4158077 C37.3309821,93.4158077 34.7643154,92.5273462 34.9617513,91.3427308 C35.1591872,90.2568333 38.0220077,89.2696538 41.3784179,89.2696538 C44.7348282,89.2696538 47.4002128,90.1581154 47.2027769,91.3427308"
            id="Fill-23"
            fill="#F0F0F0"
          ></path>
          <path
            d="M19.9570179,105.656833 C19.6608641,106.940167 16.5018897,108.026064 12.8493256,108.026064 C9.19676154,108.026064 6.63009487,106.940167 6.92624872,105.656833 C7.32112051,104.3735 10.4800949,103.386321 13.9352231,103.386321 C17.3903513,103.386321 20.2531718,104.3735 19.9570179,105.656833"
            id="Fill-25"
            fill="#F0F0F0"
          ></path>
          <path
            d="M29.6311795,124.117287 C29.3350256,125.598056 25.9786154,126.88139 22.1286154,126.88139 C18.2786154,126.88139 15.3170769,125.696774 15.7119487,124.117287 C16.0081026,122.636518 19.3645128,121.451903 23.2145128,121.451903 C27.0645128,121.451903 29.9273333,122.636518 29.6311795,124.117287"
            id="Fill-27"
            fill="#F0F0F0"
          ></path>
          <path
            d="M66.9463667,138.727346 C66.9463667,139.813244 64.6758538,140.701705 61.9117513,140.701705 C59.1476487,140.701705 57.0745718,139.813244 57.0745718,138.727346 C57.1732897,137.641449 59.3450846,136.752987 62.1091872,136.752987 C64.7745718,136.654269 66.9463667,137.542731 66.9463667,138.727346"
            id="Fill-29"
            fill="#F0F0F0"
          ></path>
          <path
            d="M129.040351,131.718767 C129.237787,132.804664 127.263428,133.593421 124.598044,133.593421 C121.932659,133.593421 119.662146,132.705946 119.46471,131.718767 C119.267274,130.631882 121.241633,129.843126 123.907018,129.843126 C126.472697,129.843126 128.842915,130.631882 129.040351,131.718767"
            id="Fill-31"
            fill="#F0F0F0"
          ></path>
          <path
            d="M151.449523,110.099141 C151.745677,110.987603 149.968754,111.678628 147.500805,111.678628 C145.032856,111.678628 142.861062,110.987603 142.663626,110.099141 C142.465203,109.210679 144.144395,108.519654 146.612344,108.519654 C149.080292,108.519654 151.152382,109.210679 151.449523,110.099141"
            id="Fill-33"
            fill="#F0F0F0"
          ></path>
          <path
            d="M12.4543551,124.413244 C12.2569192,125.400423 9.88768846,126.190167 7.32102179,126.190167 C4.75435513,126.190167 2.8787141,125.400423 3.17486795,124.413244 C3.47102179,123.426064 5.74153462,122.636321 8.30820128,122.636321 C10.8748679,122.636321 12.750509,123.426064 12.4543551,124.413244"
            id="Fill-36"
            fill="#F0F0F0"
          ></path>
          <path
            d="M8.60445385,101.905749 C8.40701795,102.695492 6.23522308,103.386518 3.86599231,103.386518 C1.49676154,103.386518 -0.18144359,102.695492 0.0159923077,101.905749 C0.312146154,101.116005 2.38522308,100.424979 4.6557359,100.424979 C6.92624872,100.424979 8.80188974,101.116005 8.60445385,101.905749"
            id="Fill-38"
            fill="#F0F0F0"
          ></path>
          <path
            d="M46.6108641,86.7027897 C46.5121462,87.3938154 44.6365051,87.9861231 42.4647103,87.9861231 C40.2929154,87.9861231 38.5159923,87.3938154 38.7134282,86.7027897 C38.8121462,86.0117641 40.6877872,85.4194564 42.8595821,85.4194564 C45.0313769,85.4194564 46.7095821,86.0117641 46.6108641,86.7027897"
            id="Fill-40"
            fill="#F0F0F0"
          ></path>
          <path
            d="M125.38759,90.9474641 C125.585026,91.7381949 123.906821,92.3295154 121.636308,92.3295154 C119.365795,92.3295154 117.391436,91.7381949 117.292718,90.9474641 C117.194,90.1577205 118.872205,89.5664 121.044,89.5664 C123.215795,89.5664 125.190154,90.1577205 125.38759,90.9474641"
            id="Fill-42"
            fill="#F0F0F0"
          ></path>
          <g id="Group-52" transform="translate(28.628205, 0.522218)">
            <path
              d="M66.750609,49.2090218 C80.2029038,41.5327141 89.2721218,27.0577013 89.2721218,10.4582782 C89.2721218,7.36248333 88.9552372,4.34072692 88.3560192,1.42361154 C88.3560192,1.42361154 83.9995962,0.102765385 76.931391,0.561803846 C69.847391,1.02182949 63.0398013,3.24495769 56.9133654,6.77116282 C55.3348654,7.68035513 53.7968397,8.67247051 52.3150833,9.74750897 C46.8382115,13.7218936 36.9427244,19.2935346 24.9188782,18.8749705 C6.73996795,18.2441628 0.347980769,11.6498038 0.094275641,11.381291 C0.0932884615,11.381291 0.0932884615,11.3822782 0.0923012821,11.3832654 L0.0923012821,11.3832654 C0.425967949,27.7013423 9.51887821,41.8644064 22.8625833,49.3610474 C33.0937115,55.1084064 39.6367372,65.7126885 39.6367372,77.447291 L39.6367372,81.9014449 C39.6367372,88.6902782 38.0345449,94.9124705 35.2724167,100.353804 C25.3532372,101.542368 18.0441603,104.545368 17.5870962,108.193983 C16.9957756,113.03215 29.1370962,117.178304 44.6368013,117.178304 C60.1355192,117.178304 72.2778269,113.129881 71.7842372,108.193983 C71.39825,104.493047 63.9035833,101.454509 53.7731474,100.301483 C51.2548526,95.0496885 49.8056731,89.1068679 49.8056731,82.6625603 L49.8056731,77.793791 C49.8056731,65.8854449 56.4079295,55.1113679 66.750609,49.2090218"
              id="Fill-50"
              fill="#F0F0F0"
            ></path>
            <path
              d="M24.9188782,18.8749705 C36.9427244,19.2935346 46.8382115,13.7218936 52.3150833,9.74750897 C53.7968397,8.67247051 55.3348654,7.68035513 56.9133654,6.77116282 C56.9577885,6.73661154 57.0012244,6.70502179 57.0456474,6.67047051 C57.0456474,6.67047051 52.9221987,8.23515 43.0296731,7.72082949 C33.1381346,7.20552179 9.74889103,-2.50832436 0.094275641,11.381291 C0.347980769,11.6498038 6.73996795,18.2441628 24.9188782,18.8749705"
              id="Fill-44"
              fill="#DADCDD"
            ></path>
          </g>
        </g>
      </g>
    </g>
  </svg>
)

export default LogotypeWhite
