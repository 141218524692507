import React from "react"
import useNetlifyForm from "./hooks/NetlifyFormHook"

const ContactForm = (props) => {
  const { children, id, pageTitle } = props
  const { inputs, handleInputChange, handleSubmit } = useNetlifyForm()

  return (
    <section className="grid-form-section">
      <div className="container">
        <div className="ten columns offset-by-one">
          <div className="title">
            {children || (
              <div>
                <h6>tell us about your idea</h6>
                <h3>
                  {`We've been`}
                  <br />
                  expecting you
                </h3>
                <p className="small">
                  Fill out the form or send us an email
                  <br />
                  at ricky@futurefoundry.co
                </p>
              </div>
            )}
          </div>
          <form
            className="black-text"
            onSubmit={handleSubmit}
            name={`${id || "contact-page"}-form`}
            id={`${id || "contact-page"}-form`}
            method="POST"
            data-netlify="true"
            data-netlify-honeypot="bot-field"
          >
            <input type="hidden" name="bot-field" />
            <input type="hidden" name="form-name" value="contact-page-form" />
            <div className="name-input">
              <label htmlFor="name" className="hidden-label">
                Name
              </label>
              <input
                type="text"
                name="name"
                id="name"
                placeholder="What's your name?"
                onChange={handleInputChange}
                value={inputs.name || ""}
                required
              />
            </div>
            <div>
              <label htmlFor="email" className="hidden-label">
                Email
              </label>
              <input
                type="email"
                name="email"
                id="email"
                placeholder="Your best email address"
                onChange={handleInputChange}
                value={inputs.email || ""}
                required
              />
            </div>
            <div>
              <label htmlFor="companyName" className="hidden-label">
                Company Name
              </label>
              <input
                type="text"
                name="companyName"
                id="companyName"
                placeholder="Company Name"
                onChange={handleInputChange}
                value={inputs.companyName || ""}
                required
              />
            </div>
            <div>
              <label htmlFor="companyWebsite" className="hidden-label">
                Company Website
              </label>
              <textarea
                type="textarea"
                name="companyWebsite"
                id="companyWebsite"
                onChange={handleInputChange}
                value={inputs.companyWebsite || ""}
                placeholder="Tell us about your project"
              />
            </div>
            <div>
              <label htmlFor="pageTitle" className="hidden-label">
                Page Title
              </label>
              <textarea
                type="text"
                name="pageTitle"
                id="pageTitle"
                className="hidden"
                value={pageTitle || "Contact Page"}
              />
            </div>
            <button className="blue-button" type="submit">
              Submit
            </button>
          </form>
        </div>
      </div>
    </section>
  )
}

export default ContactForm
