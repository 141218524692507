/**
 * This component renders a table of sorted projects. If displayAll is false,
 * only one row of three projects will be rendered. If displayAll is false and
 * projectToExclude is provided, (this would be the case on an individual project page
 * where you wouldn't want that featured project included in a selection of other work)
 * the three projects displayed will be the prior project and the next two projects.
 *
 * This component is currently used on the OurWork page, in the Our Work section of the
 * Startups page and in the project template.
 */

import React from "react"
import { Link } from "gatsby"
import PropTypes from "prop-types"
import { GatsbyImage } from "gatsby-plugin-image"
import { useContentfulOurWork } from "./hooks/useContentfulOurWork"

const ProjectsTable = ({ projects, displayAll, projectToExclude }) => {
  const renderProjectsTable = (projects) => {
    let projectsToRender = [...projects]

    if (!displayAll && projects.length > 3) {
      if (projectToExclude) {
        const len = projectsToRender.length
        const currentIndex = projectsToRender.findIndex(
          (p) => p.id === projectToExclude
        )
        const previous = projectsToRender[(currentIndex + len - 1) % len]
        const next = projectsToRender[(currentIndex + 1) % len]
        const following = projectsToRender[(currentIndex + 2) % len]
        projectsToRender = [previous, next, following]
      } else {
        projectsToRender = projectsToRender.slice(0, 3)
      }
    }

    const table = []
    while (projectsToRender.length) {
      const row = projectsToRender.splice(0, 3)
      table.push(
        <div key={row[0].id} className="table-row">
          {row.map((project, i) => {
            const img = project.primaryImage
            return (
              <div
                key={i}
                className={`one-third column project ${
                  i % 3 === 0 ? "first-in-row" : ""
                }`}
              >
                <Link to={`/our-work/${project.slug}`}>
                  <GatsbyImage
                    alt={project.client}
                    className={`image ${displayAll ? "" : "tall-image"}`}
                    image={img?.gatsbyImageData}
                  />
                </Link>
                <div
                  style={{
                    textAlign: "left",
                  }}
                >
                  <Link to={`/our-work/${project.slug}`}>
                    <p className="date">{project.client}</p>
                  </Link>
                  <Link to={`/our-work/${project.slug}`}>
                    <h4 className="description">{project.description}</h4>
                  </Link>

                  {displayAll ? null : (
                    <Link to={`/our-work/${project.slug}`}>
                      <h6 className="green-text">View Project</h6>
                    </Link>
                  )}
                </div>
              </div>
            )
          })}
        </div>
      )
    }
    return table
  }

  const ourWork = useContentfulOurWork()

  return (
    <section className="table-section dark">
      <div className="container">
        <div className={displayAll ? "" : "eleven columns offset-by-one-half"}>
          {renderProjectsTable(projects ? projects : ourWork?.projects)}
        </div>
      </div>
    </section>
  )
}

ProjectsTable.propTypes = {
  displayAll: PropTypes.bool,
  projectToExclude: PropTypes.string,
}

export default ProjectsTable
