import React from "react"

class WidthAwareComponent extends React.Component {
  state = {
    width: window.innerWidth,
  }

  constructor(props) {
    super(props)

    this.canvasHeight
    this.canvasWidth
  }

  componentDidMount() {
    window.addEventListener("resize", this.updateDimensions)
    this.determineScreenSizeAndDraw(true)
  }

  componentDidUpdate(prevProps, prevState) {
    const { width } = this.state
    if (prevState.width !== width) {
      this.determineScreenSizeAndDraw(false, prevState.width)
    }
  }

  componentWillUnmount() {
    // Overridden in Leadership Animation.
    // window.removeEventListener("resize", this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth })
  }

  // determineScreenSizeAndDraw = (initial, prevWidth) => {
  // }

  render() {
    return <></>
  }
}

export default WidthAwareComponent
