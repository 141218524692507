import React, { useState } from "react"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import loadable from "@loadable/component"
import Arrow from "./Arrow"
const Modal = loadable(() => import("./TermsModal"))

const Footer = () => {
  const [modalInfo, setModalInfo] = useState(false)
  const [mailchimpEmail, setMailchimpEmail] = useState("")
  const saveEmailValue = () => setMailchimpEmail(event.target.value)

  return (
    <footer className="blue">
      <div className="container">
        <div className="row">
          <div className="seven columns offset-by-one">
            <div className="idea">
              <h4 className="idea-text">Have an idea?</h4>
              <Link to="/contact">
                <div className="lets-talk green-text">
                  <Arrow>
                    <h5>{`Let's talk!`}</h5>
                  </Arrow>
                </div>
              </Link>
            </div>
            {/* <!-- Begin Mailchimp Signup Form --> */}
            <div id="mc_embed_signup" className="newsletter-signup">
              <form
                action="https://futurefoundry.us4.list-manage.com/subscribe/post?u=fc56e3c6108de52cbdc0e645f&amp;id=21d5726cba"
                method="post"
                id="mc-embedded-subscribe-form"
                name="mc-embedded-subscribe-form"
                className="validate"
                target="_blank"
                noValidate
              >
                <label htmlFor="mce-EMAIL">Sign up for our newsletter!</label>
                <div id="mc_embed_signup_scroll">
                  <input
                    type="email"
                    value={mailchimpEmail}
                    onChange={saveEmailValue}
                    name="EMAIL"
                    className="email"
                    id="mce-EMAIL"
                    required
                  />
                  {/* <!-- real people should not fill this in and expect good things - do not remove this or risk form bot signups--> */}
                  <div
                    style={{ position: "absolute", left: "-5000px" }}
                    aria-hidden="true"
                  >
                    <input
                      type="text"
                      name="b_fc56e3c6108de52cbdc0e645f_21d5726cba"
                      tabIndex="-1"
                      defaultValue=""
                    />
                  </div>
                  <div className="clear">
                    <input
                      type="submit"
                      value="Submit"
                      name="subscribe"
                      id="mc-embedded-subscribe"
                      className="button"
                    />
                  </div>
                </div>
              </form>
            </div>
            {/* <!--End mc_embed_signup--> */}
          </div>
          <div className="three columns footer-site-links">
            <ul>
              <li>HOME</li>
              <li>
                <Link to="/">Corporate</Link>
              </li>
              <li>
                <Link to="/startups">Startups</Link>
              </li>
              <li>
                <Link to="/our-work">Our Work</Link>
              </li>
            </ul>
            <ul>
              <li>COMPANY</li>
              <li>
                <Link to="/contact">Contact</Link>
              </li>
              <li>
                <Link to="/join-us">Join Us</Link>
              </li>
              <li>
                <Link to="/blog">Blog</Link>
              </li>
            </ul>
          </div>
        </div>
        <div className="row social-and-location">
          <div className="five columns offset-by-one">
            <div className="social-icons">
              <a
                href="https://www.linkedin.com/company/futurefoundry"
                aria-label="linked in"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  aria-hidden="true"
                  className="social"
                  icon={["fab", "linkedin"]}
                />
              </a>
              <a
                href="https://github.com/future-foundry"
                aria-label="github"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  aria-hidden="true"
                  className="social"
                  icon={["fab", "github"]}
                />
              </a>
              <a
                href="https://www.facebook.com/futurefoundryco/"
                aria-label="facebook"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FontAwesomeIcon
                  aria-hidden="true"
                  className="social"
                  icon={["fab", "facebook-square"]}
                />
              </a>
            </div>
          </div>
          <div className="five columns location-container">
            <div className="location">
              <FontAwesomeIcon
                className="arrow"
                icon="location-arrow"
                aria-hidden="true"
              />
              New York, NY
            </div>
            <div className="location">
              <FontAwesomeIcon
                className="arrow"
                icon="location-arrow"
                aria-hidden="true"
              />
              Los Angeles, CA
            </div>
          </div>
        </div>
        <div className="ten columns offset-by-one copyright">
          <div className="seven columns">
            <p className="caption">
              © Future Foundry {new Date().getFullYear()} - All Rights Reserved.
            </p>
          </div>
          <div className="five columns terms">
            <a onClick={() => setModalInfo("terms")}>Terms</a>
            <a onClick={() => setModalInfo("privacy")}>Privacy Policy</a>
            {modalInfo && (
              <Modal modalInfo={modalInfo} setModalInfo={setModalInfo} />
            )}
          </div>
        </div>
      </div>
    </footer>
  )
}

export default Footer
