/**
 * This component renders a table of services and an optional link in the
 * last cell.
 *
 * This component is currently used in the Services sections of the Index and Startup pages.
 */

import React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import Arrow from "./Arrow"

const ServicesTable = (props) => {
  const { darkMode, link, services } = props
  const servicesToRender = [...services]
  if (link === true) {
    servicesToRender.push("link")
  }
  const table = []

  while (servicesToRender.length) {
    const row = servicesToRender.splice(0, 3)
    table.push(
      <div key={row[0]} className="desk-flex row">
        {row.map((service, i) => {
          if (service === "link") {
            return (
              <div key={i} className="one-third column link last-link">
                <Link
                  to="/our-work"
                  className={`small ${darkMode ? "green-text" : "purple-text"}`}
                >
                  <Arrow>Check out our work</Arrow>
                </Link>
              </div>
            )
          } else {
            return (
              <div
                key={i}
                className={`one-third column cell ${
                  darkMode ? "" : "dark-purple"
                }`}
              >
                <p className={`small ${darkMode ? "" : "white-text"}`}>
                  {service}
                </p>
              </div>
            )
          }
        })}
      </div>
    )
  }
  return table
}

ServicesTable.propTypes = {
  services: PropTypes.arrayOf(PropTypes.string),
  link: PropTypes.bool,
}

export default ServicesTable
