import React from "react"
import PropTypes from "prop-types"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import { LogoFadeMobile } from "../components"

const LogoBar = (props) => {
  const { images } = props
  images.sort((a, b) => (a.name > b.name ? 1 : b.name > a.name ? -1 : 0))
  const rwp = images.slice(2, 3)[0]
  const imagesReordered = images.filter((i) => i.name !== "realworld")
  imagesReordered.push(rwp)
  return (
    <>
      <div className="desktop-only">
        <div className="logo-bar">
          <div className="logos twelve columns">
            {imagesReordered.map((image) => {
              return (
                <GatsbyImage
                  alt={image.name ? image.name.split(".")[0] : "logo"}
                  className={image.name}
                  image={image.childImageSharp.gatsbyImageData}
                  key={image.id}
                />
              )
            })}
          </div>
        </div>
      </div>

      <div className="mobile-only">
        <LogoFadeMobile images={imagesReordered} />
      </div>
    </>
  )
}

LogoBar.propTypes = {
  images: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      childImageSharp: PropTypes.object,
    })
  ),
}

export default LogoBar
